import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { getAuth } from '@firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "Toni Costa"
    }
    
  },

  {
    path: '/mostrari/:indexCateg',
    name: 'mostrari',
    component: () => import('../views/Mostrari.vue'),
    meta: {
      title: "Toni Costa"
    }
  },

  {
    path: '/access',
    name: 'Access',
    component: () => import('../views/Acces.vue'),
    meta: {
      title: "Toni Costa"
    }
  },


  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      auth: true,
      title: "Toni Costa"
    }
  },

  {
    path: "*",
    component: HomeView,
    meta: {
      title: "Toni Costa"
    }
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Bloquejar rutes
router.beforeEach(async (to, from, next) => {
  const rutaAuth = to.matched.some(record => record.meta.auth)
  const user = getAuth()

  if (rutaAuth){
    if (user.currentUser){
      next()
    } else {
      next('access')
    }
  }else{
    next()
  }
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title
// If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // Continue resolving the route
  next()
})

export default router
