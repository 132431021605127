<template>
  <div>
    <v-row>
      <v-col>
        <h3 class="mb-n3">{{ $t("home.novetats") }}</h3>
      </v-col>
    </v-row>
    <!-- 
    <v-row>
      <v-col class=" d-flex justify-center">
        <iframe
          :src="src"
          frameborder="0"
          height="500"
        ></iframe>
      </v-col>
    </v-row> -->

    <v-row>
      <v-carousel
        hide-delimiters
        class="d-flex justify-center"
      >
        <v-carousel-item v-for="(src, index) in srcs" :key="index">
          <iframe
            :src="'https://www.instagram.com/p/' + src + '/embed'"
            frameborder="0"
            height="1500"
          ></iframe>
          <p>src</p>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </div>
</template>

<script>
import { db } from "../firebase";

export default {
  name: "FeedInsta",

  data() {
    return {
      srcs: [],
    };
  },

  mounted() {
    this.getInstaUrl();
  },

  methods: {
    async getInstaUrl() {
      const url = await db.collection("Altres").doc("urlInsta").get();

      // this.src = "https://www.instagram.com/p/" +  url.data().url + "/embed"
      const srcs = url;
      this.srcs = srcs.data().url
    },
  },
};
</script>