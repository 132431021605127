<template>
  <v-container>
    <v-card-title>{{ $t("mostrari.categ") }}</v-card-title>
    <v-card-text>
      <v-btn-toggle class="row" v-model="indexCateg">
        <v-btn
          class="col-12 col-sm-6 col-md-4 col-lg-4"
          @click="setCategoria(categoria.id, index)"
          v-for="(categoria, index) in categories"
          :key="index"
          small
          width="400px"
          >{{ categoria.name[$i18n.locale] }}</v-btn>
      </v-btn-toggle>
    </v-card-text>

    <v-card-title v-if="tipus.length != 0">
      {{ $t("mostrari.tipus") }}
    </v-card-title>
    <v-card-text>
      <v-btn-toggle class="row">
        <v-btn
          class="col-12 col-sm-6 col-md-4 col-lg-3"
          small
          @click="setTipus(tipo.id)"
          :value="tipo"
          v-for="(tipo, index) in tipus"
          :key="index"
          width="400px"
          >{{ tipo.name[$i18n.locale] }}</v-btn
        >
      </v-btn-toggle>
    </v-card-text>
  </v-container>
</template>

<script>
import { db } from "../firebase";

export default {
  name: "Categories",

  data() {
    return {
      categories: [],
      categSelected: "",

      indexCateg: "",

      tipus: [],
      tipusSelected: "",
    };
  },

  async created() {
    let test = await this.getCategories();

    this.categFromUrl();
  },

  methods: {
    categFromUrl() {
      if (this.$route.params.indexCateg == "all" || this.$route.params.indexCateg == undefined) {
      } else {
        this.indexCateg = this.$route.params.indexCateg;
        this.setCategoria(this.categories[this.indexCateg].id, this.indexCateg);
      }
    },

    async getCategories() {
      this.categories = []
      const collection = await db.collection("Categories2").get();

      collection.forEach((doc) => this.categories.push(doc.data()));
    },

    setCategoria(categ, index) {
      if (this.categSelected != categ) {
        this.categSelected = categ;
        console.log(index)
        this.getTipus(index);
      } else {
        this.categSelected = "";
        this.tipus = [];
      }
      this.$emit("categoria", this.categSelected);
    },

    getTipus(index) {
      if (this.categories[index].tipus != null) {
        this.tipus = this.categories[index].tipus;
      } else {
        this.tipus = [];
      }
    },

    setTipus(tipo) {
      if (this.tipusSelected != tipo) {
        this.tipusSelected = tipo;
      } else {
        this.tipusSelected = "";
      }
      this.$emit("tipus", this.tipusSelected);
    },
  },
};
</script>