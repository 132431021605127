<template>
  <v-footer class="mt-3 pb-3" dark>
    <div class="row mt-1">
      <v-col class="d-flex justify-center align-center">
        <v-img
          alt="Toni Costa"
          class="shrink mr-2 img-footer"
          contain
          src="@/assets/logo-tonicosta.png"
          transition="scale-transition"
          width="110"
        />
      </v-col>

      <v-col class="d-flex justify-center align-center col-12 col-sm-4">
        <div>
          <v-btn
            fab
            href="https://www.facebook.com/ferreriatonicosta.costa"
            color="#BFBFBF"
            dark
            class="ma-3"
            height="40px"
            width="40px"
          >
            <v-icon color="#3E441D">mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            fab
            href="https://www.instagram.com/ferreriatonicosta"
            target="_blank"
            color="#BFBFBF"
            dark
            class="ma-3"
            height="40px"
            width="40px"
            ><v-icon color="#3E441D">mdi-instagram</v-icon>
          </v-btn>

          <v-btn
            fab
            href="https://wa.me/34648292281"
            target="_blank"
            color="#BFBFBF"
            dark
            class="ma-3"
            height="40px"
            width="40px"
            ><v-icon color="#3E441D">mdi-whatsapp</v-icon></v-btn
          >
        </div>
      </v-col>

      <v-col class="d-flex justify-center">
        <div class="div-links">
          <a href="https://goo.gl/maps/qiAp7gzFVfLp6ci97" target="_blank"
            ><p class="ma-2 text-center">C/ Camps s/n, 07510, Sineu</p></a
          >
          <a href="tel:+34971520813"  target="_blank"
            ><p class="ma-2 text-center">971 52 08 13</p></a
          >
          <a href="mailto:info@tonicostaferroialumini.com" target="_blank"
            ><p class="ma-2 text-center">info@tonicostaferroialumini.com</p></a
          >
        </div>
      </v-col>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.img-footer {
  -webkit-filter: grayscale(50);
  filter: grayscale(1) invert(1) saturate(100%);
}
.div-links {
  a {
    color: white;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  p {
    color: #BFBFBF;
    font-size: 10px;
  }
}
</style>