<template>
  <div>
    <v-card>
      <v-card-title>Els nostres productes</v-card-title>
      <v-card-text class=" d-flex justify-center">
        <v-btn-toggle class="row">
          <v-col
            class="col-6 col-md-4 col-lg-2"
            v-for="(categoria, index) in categories"
            :key="index"
          >
            <div class="d-flex justify-center">
              <button class="">
                <v-sheet
                  class="btn-categ d-flex align-center justify-center"
                  color="success"
                  elevation="11"
                  height="140px"
                  width="140px"
                  :style="{
                    backgroundImage:
                      'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' +
                      'url(' +
                      categoria.img +
                      ')',
                  }"
                >
                  <router-link
                    :to="{ name: 'mostrari', params: { indexCateg: index } }"
                    ><p style="text-transform: uppercase">{{ categoria.name[$i18n.locale] }}</p></router-link
                  ></v-sheet
                >
              </button>
            </div>
          </v-col>
        </v-btn-toggle>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db } from "../firebase";

export default {
  name: "Categories2",

  data() {
    return {
      categories: [],


      categSelected: "",

    };
  },

  created() {
    this.getCategories();
  },

  methods: {
    async getCategories() {
      const collection = await db.collection("Categories2").get();

  

      collection.forEach ((cat) => this.categories.push(cat.data()))

  

      


    },

    // setCategoria(categ) {
    //   if (this.categSelected != categ) {
    //     this.categSelected = categ;
    //   } else {
    //     this.categSelected = "";
    //   }
    //   this.$emit("categoria", this.categSelected[0]);
    // },
  },
};
</script>

<style lang="scss" scoped>
.btn-categ {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 5px;
  min-height: 50px;
  min-width: 50px;

  p {
    font-size: 1.2em;
    font-weight: 500;
    padding: 5px;
    color: white;
  }
}
</style>