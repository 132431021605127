<template>
  <v-container>
    <label><v-icon class="mr-2" color="black">mdi-translate</v-icon> </label>
    <select class="text-uppercase" v-model="$i18n.locale">
      <option class="text-uppercase" v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
    
  </v-container>
</template>

<script>
export default {
  name: "LocalSwitcher",

  data() {
    return {
      langs: ["ca", "es", "en"],
    };
  },
};
</script>