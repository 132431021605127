import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'

var firebaseConfig = {
    apiKey: "AIzaSyB5ucpB5QhgUpG-x96mQCPoM6rcXKUmgi4",
    authDomain: "tonicosta-653a4.firebaseapp.com",
    projectId: "tonicosta-653a4",
    storageBucket: "tonicosta-653a4.appspot.com",
    messagingSenderId: "760497648269",
    appId: "1:760497648269:web:da81a91724ea2ed779a8c4"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const fv = firebase.firestore.FieldValue;

export { db ,storage, auth, fv };


