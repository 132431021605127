<template>
  <v-container fluid class="home" id="inici">
    <Baner />
    <v-container>
      <Categories2 class="mt-n9"/>

      <v-row class="mt-5">
        <v-col>
          <h3>{{ $t("home.empresa.titol") }}</h3>
          <p>
            {{ $t("home.empresa.p1") }}
          </p>
          <p>
           {{ $t("home.empresa.p2") }}
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <FeedInsta />
        </v-col>
        <v-col id="contacte">
          <Contacte />
        </v-col>
      </v-row>

      <Fabricants />
    </v-container>
  </v-container>
</template>

<script>
import Categories from "../components/Categories.vue";
import Baner from "../components/Baner.vue";
import Fabricants from "../components/Fabricants.vue";
import FeedInsta from "../components/Instagram.vue";
import Contacte from "../components/contacte.vue";

import Categories2 from "../components/Categories2.vue";

export default {
  name: "Home",

  components: {
    Categories,
    Baner,
    Fabricants,
    FeedInsta,
    Contacte,

    Categories2,
  },
};
</script>

<style scoped>
.home {
  margin: 0;
  padding: 0;
}
</style>
