<template>
<v-parallax height="300" src="../assets/slide/slide_barreres.jpg" class="banner"> 

  <v-row align="center" justify="center">
    <v-col class="text-center">
      <div class="text-banner">
        <h1>{{ $t("home.baner.titol") }}</h1>
        <hr class="linea"/>
        <p>{{ $t("home.baner.lema") }}</p>
      </div>
      <div class="mt-10">
        <v-btn  ><router-link to="/mostrari/all"> <span class="text1">{{ $t("home.baner.action") }}</span>  </router-link></v-btn>
      </div>
    </v-col> 
  </v-row> 
</v-parallax>
</template>

<script>
  export default {
    name: 'Baner',

  }
</script>

<style lang="scss">


.text-banner{
  color: #FFF;
  border-color: #FFF;

  .linea{
    border-color: #FFF;
    max-width: 60%;
    margin: 0 auto;
  }
  background-image: url();
}

.text1{
  color: #3E441D;
}



</style>
