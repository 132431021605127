var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Els nostres productes")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-btn-toggle',{staticClass:"row"},_vm._l((_vm.categories),function(categoria,index){return _c('v-col',{key:index,staticClass:"col-6 col-md-4 col-lg-2"},[_c('div',{staticClass:"d-flex justify-center"},[_c('button',{},[_c('v-sheet',{staticClass:"btn-categ d-flex align-center justify-center",style:({
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' +
                    'url(' +
                    categoria.img +
                    ')',
                }),attrs:{"color":"success","elevation":"11","height":"140px","width":"140px"}},[_c('router-link',{attrs:{"to":{ name: 'mostrari', params: { indexCateg: index } }}},[_c('p',{staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(categoria.name[_vm.$i18n.locale]))])])],1)],1)])])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }