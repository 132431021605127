import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)


export default new Vuex.Store({
  //Estats globals
  state: {


  },

  getters: {

  },
  mutations: {


  },

  //Peticions / Metodes
  actions: {


  },
  modules: {
  }
})
