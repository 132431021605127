<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title><a href="/">
          <div class="d-flex align-center">
            <v-img alt="Toni Costa" class="shrink mr-2" contain src="@/assets/logo-tonicosta.png"
              transition="scale-transition" width="90" />
          </div>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <LocalSwitcher />
      </div>
      <div>
        <v-btn @click="logout" v-if="isLogged"> <v-icon>mdi-location-exit</v-icon> </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav>
        <v-list-item-group v-model="group" active-class="light-green darken-4--text text--accent-4">
          <v-list-item href="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("menu.inici") }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/mostrari/all">
            <v-list-item-icon>
              <v-icon>mdi-panorama-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("menu.mostrari") }}</v-list-item-title>
          </v-list-item>

          <v-list-item href="/#contacte">
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("menu.contacte") }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LocalSwitcher from "@/components/LocalSwitcher.vue";

import { getAuth, signOut, onAuthStateChanged } from "@firebase/auth";

export default {
  name: "App",

  components: {
    Footer,
    LocalSwitcher,
  },

  data: () => ({
    drawer: false,
    group: null,

    isLogged: false,
    //
  }),

  created() {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        this.isLogged = true;
      } else {
        this.isLogged = false;
      }
    });

  },

  methods: {

    logout() {
      signOut(getAuth()).then(() => {
        this.$router.push({ path: "/" });
      });
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

button {
  a {
    color: #3e441d;
    text-decoration: none;
  }

  .link {
    text-decoration: none;
  }
}
</style>
