<template>
  <div mt-3>
    <v-row>
      <v-col>
        <h2>{{ $t("home.fabricants") }}</h2>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col align="center" class="col-6 col-md-3 pa-lg-15 pa-5">
        <a href="https://www.cortizo.com" target="_blank">
          <v-img
            src="@/assets/brands/logo-cortizo.png"
            max-height="100"
            with
            contain
          ></v-img>
        </a>
      </v-col>
      <v-col align="center" class="col-6 col-md-3 pa-lg-15 pa-5">
        <a href="https://www.reynaers.es" target="_blank">
          <v-img
            src="@/assets/brands/logo-reynaers.svg"
            max-height="100"
            with
            contain
          ></v-img>
        </a>
      </v-col>
      <v-col align="center" class="col-6 col-md-3 pa-lg-15 pa-5">
        <a href="https://www.technal.com" target="_blank">
          <v-img
            src="@/assets/brands/logo-technal.png"
            max-height="100"
            with
            contain
          ></v-img>
        </a>
      </v-col>
      <v-col align="center" class="col-6 col-md-3 pa-lg-15 pa-5">
        <a href="https://www.forster-profile.ch/" target="_blank">
          <v-img
            src="@/assets/brands/logo-forster-profile.png"
            max-height="100"
            with
            contain
          ></v-img>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Fabricants",
};
</script>